import csApi from "./cs-api"
import _ from "lodash"
import storage from "./storage"

function varNameToKey(name) {
  // "some.name" -> "/some/name"
  return "/" + name.replace(/\./, "/");
}

function chainResolvers(resolvers) {
  return (n) => {
    for(let idx = 0; idx < resolvers.length; idx++) {
      let r = resolvers[idx];
      let v = r(n);
      if(v != null) return v;
    }
    return null;
  }
}

function formatVariable(name) {
  return "${" + name + "}";
}

function processTokens(text, variableResolver, variableFormatter) {
  return text.value.tokens.map(token => {
    let v = null;
    switch(token.type) {
      case "STRING":
        v = token.value;
        break;
      case "VARIABLE":
        if(variableResolver != null) {
          v = variableResolver(token.value);
        }
        if(v == null) {
          // jam it back in
          v = variableFormatter(token.value);
        }
        break;
      default:
        throw new Error(`Unknown token type '${token.type}'`);
    }
    return v;
  });
}

// return a placeholder text indicating a missing key
function missingResource(key) {
  let v = `Missing key: '${key}'`
  return {
    key,
    type: "plain",
    value: {
      rawValue: v,
      tokens: [{ type: "STRING", value: v }],
      variableNames: []
    }
  };
}

class TextGetter {
  constructor(translator, key) {
    this.translator = translator;
    this.client = translator.client;
    this.key = key;
    this._setText();
  }

  get(params) {
    this._checkTranslator();
    let resolvers = []
    this.resolver = null;

    if(this.text.value.variableNames.length > 0) {
      if(!_.isEmpty(params)) {
        resolvers.push((n) => params[n]);
      }

      resolvers.push((n) => this.resolveKeyVar(n));
      this.resolver = chainResolvers(resolvers);
    }

    let varFmt = (params != null ? params.varFmt : null) || formatVariable;

    let v = processTokens(this.text, this.resolver, varFmt).join("");
    return { type: this.text.type, value: v }
  }

  resolveKeyVar(name) {
    if(this.resolvingKeys == null) {
      this.resolvingKeys = new Set();
    }

    let key = varNameToKey(name);
    let v = null;
    if(!this.resolvingKeys.has(key)) {
      let text = this.translator.texts[key];
      if(text != null) {
        this.resolvingKeys.add(key);
        try {
          v = processTokens(text, this.resolver).join("");
        } finally {
          this.resolvingKeys.delete(key);
        }
      }
    }

    return v;
  }

  _setText() {
    this.text = this.translator.texts[this.key];
    if(this.text == null) {
      this.text = missingResource(this.key);
    }
  }

  /** If this.client.translator has changed, reload our text as well */
  _checkTranslator() {
    let ct = this.client.translator;
    if(ct !== this.translator) {
      this.translator = ct;
      this._setText();
    }
  }
}

class Translator {
  _storageKey = "csTextResources";
  // check for changes once per minute
  _checkInterval = 1 * 60 * 1000;

  constructor(client, language) {
    this.client = client;
    this.api = client.api;
    this.language = language;
    this.textResources = null;
    this.texts = null;
    this.now = Date.now();
  }

  /** Current effective language isoCode */
  get isoCode() { return this.language != null ? this.language.isoCode : null; }

  languageMatches(l) {
    return l != null
      ? l.isoCode === this.language.isoCode
      : this.language.master;
  }

  setup() {
    console.debug("loading text resources");
    return this._loadCachedResources()
      .then(trs => {
        this.textResources = trs;
        this.language = trs.language;
        this.texts = {}
        trs.resources.forEach(tr => {
          this.texts[tr.key] = tr;
        });

        return this;
      });
  }

  tr(key, params) { return this.getter(key).get(params); }

  /** Given a key, returns a callable with optional params returning it's value.
   */
  getter(key) {
    return new TextGetter(this, key);
  }

  /** Given {a: "/key/a", b: "/key/b"}, returns {a: getter, b: getter}.
   *
   * Each getter is callable with optional params.
   */
  getters(keymap) {
    let o = {};
    _.forIn(keymap, (key, name) => {
      if(key.key != null) {
        // if we're being called on our own results, handle it
        key = key.key;
      }
      let g = new TextGetter(this, key);
      o[name] = g;
    });
    return o;
  }

  _loadCachedResources() {
    let resourcesStr = storage.local.getItem(this._storageKey);
    let resources = null;
    if(resourcesStr != null) {
      try {
        resources = JSON.parse(resourcesStr);
      } catch(err) {
      }
    }

    if(resources != null && (this.isoCode != null
                             ? resources.language.isoCode === this.isoCode
                             : resources.language.master)) {
      return this._checkCachedResources(resources);
    } else {
      return this._loadResources();
    }
  }

  _checkCachedResources(trs) {
    if(trs.loaded + this._checkInterval <= this.now) {
      // check for new version
      console.debug("checking for changes");
      return this.api.text.status(this.isoCode)
        .then(trStatus => {
          let changed = trs.lastModified < trStatus.lastModified;
          if(changed) {
            return this._loadResources();
          } else {
            console.debug("no changes");
            trs.loaded = this.now;
            storage.local.setItem(this._storageKey, JSON.stringify(trs));
            return Promise.resolve(trs);
          }
        });
    } else {
      console.debug("using cached resources");
      return Promise.resolve(trs);
    }
  }

  _loadResources() {
    console.debug("loading resources from server");
    return this.api.text.resources(this.isoCode)
      .then(trs => {
        trs.loaded = this.now;
        storage.local.setItem(this._storageKey, JSON.stringify(trs));
        return trs;
      });
  }
}

export default Translator;
