import csApi from "./cs-api";
import Translator from "./translator";
import Bowser from "bowser";
import _ from "lodash";
import storage from "./storage";
import util from "./util";

/**
 * Return {deviceId: ..., deviceDescription: ...} for current browser
 */
export function getDeviceInfo() {
  let deviceId = util.getCsData("deviceId");
  let deviceDescription = "Unknown";

  try {
    let bp = Bowser.parse(window.navigator.userAgent);
    let majorVersion = bp.browser.version.replace(/\..*$/, "");
    deviceDescription = `${bp.browser.name}/${majorVersion} on ${bp.os.name} ${bp.os.versionName}`;
  } catch (err) {}

  return { deviceId, deviceDescription };
}

/** Competition system client (high level .. ish).
 */
class CSClient {
  _authenticatedUser = null;
  _language = null;
  _translator = null;

  constructor(opts) {
    this.api = csApi;
    this.deviceInfo = getDeviceInfo();

    opts = Object.assign(
      { authenticatedUser: util.getCsData("authenticatedUser") },
      opts
    );

    if(opts.apiOptions != null) {
      // Note: this affects the cs-api module globally
      this.api.configure(opts.apiOptions);
    }

    this.userType = opts.userType;
    this.authenticatedUser = opts.authenticatedUser;
    if (this.authenticatedUser == null) {
      this.language = opts.language;
    }

    // FIXME: throw an error if userType is still unknown?

    this._loginMethods = {
      AdminUser: this.api.auth.admin.login,
      Competitor: this.api.auth.competitor.login,
    };

    this._resetPasswordMethods = {
      AdminUser: this.api.auth.admin.resetPassword,
      Competitor: this.api.auth.competitor.resetPassword,
    };

    if (CSClient.instance == null) {
      CSClient.instance = this;
    }
  }

  /** Perform a whoami API call and update this.authenticatedUser accordingly */
  refreshAuthenticatedUser() {
    return this.api.auth
      .whoami()
      .then((au) => {
        this.authenticatedUser = au;
      })
      .catch(() => {
        this.authenticatedUser = null;
      });
  }

  get authenticatedUser() {
    return this._authenticatedUser;
  }

  set authenticatedUser(u) {
    let typeProp = this.api.settings.propNames.type;
    if (this.userType != null && u != null && u.user[typeProp] !== this.userType) {
      console.warn(
        `authenticatedUser type '${u.user[typeProp]}' != '${this.userType}', ignoring`
      );
      this._authenticatedUser = null;
      return;
    }
    this._authenticatedUser = u;
    if (u != null) {
      if (this.userType == null) {
        this.userType = u.user[typeProp];
      }
      this.language = u.language;
    }
  }

  get language() {
    return this._language;
  }

  set language(l) {
    if (l != null && typeof l === "string") {
      // support setting by iso code
      let currentIsoCode =
        this._language != null ? this._language.isoCode : null;
      if (l === currentIsoCode) return;
      l = { isoCode: l };
    }
    this._language = l;
  }

  /** Get current translator instance.
   *
   * This is only valid after initTranslator() has been awaited.
   */
  get translator() {
    return this._translator;
  }

  /** Initialize a translator instance using this.language.
   *
   * If this.language is null, the master language is implied.
   */
  initTranslator() {
    return this._translator != null
      ? this._refreshTranslator()
      : this._createTranslator();
  }

  /** If this._translator is set, make sure it uses this.language.
   *
   */
  _refreshTranslator() {
    if (
      this._translator != null &&
      !this._translator.languageMatches(this.language)
    ) {
      return this._createTranslator();
    } else {
      return Promise.resolve(this._translator);
    }
  }

  _createTranslator() {
    return new Translator(this, this.language)
      .setup()
      .then((t) => {
        this._translator = t;
        return t;
      })
      .catch((err) => {
        console.error("_createTranslator: " + err);
      });
  }

  /** Retrieve the stored identity for this.userType
   *
   * @return a compact JWT or null
   */
  getIdentityToken() {
    let k = this._identityTokenKey;
    return k != null ? storage.local.getItem(k) : null;
  }

  /** Store an identity for this.userType
   *
   * @return the previous stored identity, if any
   */
  setIdentityToken(value) {
    let k = this._identityTokenKey;
    if (k == null) {
      throw new Error("userType is not set");
    }

    let prev = storage.local.getItem(k);

    if (value != null) {
      storage.local.setItem(k, value);
    } else {
      storage.local.removeItem(k);
    }

    return prev;
  }

  get _identityTokenKey() {
    let k = null;
    if (this.userType != null) {
      k = `csIdentity:${this.userType}`;
    }
    return k;
  }

  assureSession() {
    return this.api.session.status().then((r) => {
      if (r.valid) {
        return r;
      } else {
        return this.api.session.create();
      }
    });
  }

  /** Perform a regular username/password login.
   */
  login(opts) {
    this._checkUserType();
    let loginMethod = this._loginMethods[this.userType];
    if (loginMethod == null)
      throw new Error(`Unhandled userType ${this.userType}`);

    opts = Object.assign(
      { session: true, identity: false },
      this.deviceInfo,
      opts
    );

    opts.tokenTypes = opts.identity ? ["IDENTITY"] : [];
    // prep opts as a LoginRequest
    opts = _.omit(opts, ["identity"]);

    // make sure a confirmed session exists and perform the login
    return this.assureSession()
      .then(() => {
        return loginMethod(opts);
      })
      .then((loginResponse) => this._handleLogin(loginResponse))
      .then(() => this._refreshTranslator());
  }

  _handleLogin(lr) {
    // if set, we requested an identity token
    let prevIdToken = null;
    if (lr.identity) {
      prevIdToken = this.setIdentityToken(lr.identity);
    }

    // if this was set, user was logged in
    if (lr.authenticatedUser) {
      this.authenticatedUser = lr.authenticatedUser;
    }

    if (prevIdToken !== null) {
      return this.api.auth
        .logout({ current: false, identity: prevIdToken })
        .then((logoutRequest) => lr);
    } else {
      return lr;
    }
  }

  _handleLogout(resp) {
    this.authenticatedUser = null;
    let idToken = this.getIdentityToken();
    if(idToken != null) {
      // revoke stored id token
      return this.api.auth
        .logout({current: false, identity: idToken})
        .finally(() => {
          this.setIdentityToken(null);
        });
    }
    return resp;
  }

  /** Login using a saved identity */
  identityLogin() {
    let idToken = this.getIdentityToken();
    if (idToken == null) {
      throw new Error("No saved identity available");
    }

    let req = { identity: idToken, type: "SESSION" };

    return this.assureSession()
      .then(() => this.api.auth.login(req))
      .then((loginResponse) => this._handleLogin(loginResponse))
      .then(() => this._refreshTranslator())
      .catch((err) => {
        if (err.status === 401) {
          // we have an invalid token, ditch it
          this.setIdentityToken(null);
        }
      });
  }

  /** Perform a logout.
   */
  logout(opts) {
    opts = Object.assign({}, opts);
    return this.api.auth.logout(opts).then((resp) => this._handleLogout(resp));
  }

  /** Initiate a password reset */
  resetPassword(opts) {
    this._checkUserType();

    let resetMethod = this._resetPasswordMethods[this.userType];
    if (resetMethod == null)
      throw new Error(`Unhandled userType ${this.userType}`);

    return this.assureSession().then(() => resetMethod(opts));
  }

  _checkUserType() {
    if (this.userType == null) throw new Error("userType is not set");
  }
}

export default CSClient;
