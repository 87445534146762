<template>
  <span :style="css" class="vui-icon"></span>
</template>

<script>
let iconSize = 32;

export default {
  name: "vui-icon",
  props: {
    icon: { type: String, required: true },
    ratio: { type: Number, default: 1 },
    strokeWidth: { type: Number, default: 1 },
    iconColor: {
      type: String,
      default: "currentColor"
    }
  },
  computed: {
    src() {
      return require(`bs-icons/${this.icon}.svg`);
    },
    width() {
      return iconSize * this.ratio;
    },
    height() {
      return iconSize * this.ratio;
    },
    css() {
      return { width: this.width + "px", height: this.height + "px" };
    }
  },
  methods: {
    inject(svg) {
      this.$el.innerHTML = svg || "";
    },
    process(svg) {
      let parser = new DOMParser();
      let node = parser.parseFromString(svg, "image/svg+xml").firstChild;

      node.setAttribute("width", this.width);
      node.setAttribute("height", this.height);
      node.setAttribute("stroke-width", this.strokeWidth);
      node.setAttribute("focusable", false);

      // outerHTML for sensible browsers - otherwise IE
      return node.outerHTML || new XMLSerializer().serializeToString(node);
    },
    async loadSvg() {
      return new Promise(resolve => {
        let icon = this.$store.icon;
        if (!icon.state.cache.has(this.src)) {
          fetch(this.src)
            .then(r => {
              if (r.ok) {
                r.text().then(svg => {
                  svg = this.process(svg);
                  icon.mutations.set(this.src, svg);
                  resolve(svg);
                });
              } else throw Error(`Request rejected with status ${r.status}`);
            })
            .catch(e => {
              icon.mutations.delete(this.src);
              resolve(null);
            });
        } else {
          let svg = icon.getters.get(this.src);
          svg = this.process(svg);
          resolve(svg);
        }
      });
    }
  },
  async created() {
    this.loadSvg()
      .then(svg => {
        this.inject(svg);
      })
      .catch(e => {
        this.inject(null);
      });
  }
};
</script>

<style lang="scss">
.vui-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  transition: all 200ms ease-in-out;
  * {
    color: currentColor;
  }
  &.rotate-180 {
    transform: rotateX(180deg);
  }
  &.diagonal-strike-through {
    &:after {
      display: block;
      content: "";
      height: 1px;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: rotateZ(45deg);
      transform-origin: 0 center;
      background-color: currentColor;
      position: absolute;
    }
    &.visible:after {
      width: 125%;
    }
  }
}
</style>
