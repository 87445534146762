import _ from "lodash";
import util from "./util";

const apiRoot = "/v1";
const defaultOptions = {
  vueify: true,
};

function apiSettings(options) {
  let opts = Object.assign({}, defaultOptions, options);
  let r = Object.assign({}, opts);
  if (r.vueify) {
    r.propNames = {
      id: "_id",
      type: "_type",
    };
    r.mangle = util.vueifyProps;
    r.unmangle = util.unvueifyProps;
  } else {
    r.propNames = {
      id: "id",
      type: "@type",
    };
    r.mangle = (obj) => obj;
    r.unmangle = (obj) => obj;
  }
  return r;
}

const settings = apiSettings();

function configure(options) {
  Object.assign(settings, apiSettings(options));
}

// the collections from EntityRefs that can appear in a response, mapped to the id property used
// to refer to them
class EntityRefInfo {
  constructor(colName, idPropName, propName, idColPropName, colPropName) {
    // name of the top-level array in the response
    this.colName = colName;

    // if set to "fooId" and "foo", anywhere a "fooId" property appears, a "foo" property with the actual object will be set
    this.idPropName = idPropName;
    this.propName = propName;

    // if set to "fooIds" and "foos", same idea as above but on arrays
    this.idColPropName = idColPropName;
    this.colPropName = colPropName;
  }
}

let entityRefInfos = [
  new EntityRefInfo("competitors", "competitorId", "competitor"),
  new EntityRefInfo("teams", "teamId", "team"),
  new EntityRefInfo(
    "competitionClasses",
    "competitionClassId",
    "competitionClass",
    "competitionClassIds",
    "competitionClasses"
  ),
  new EntityRefInfo("markets", "marketId", "market"),
  new EntityRefInfo("districts", "districtId", "district"),
  new EntityRefInfo(
    "countries",
    "countryId",
    "country",
    "countryIds",
    "countries"
  ),
  new EntityRefInfo(
    "languages",
    "languageId",
    "language",
    "languageIds",
    "languages"
  ),
  new EntityRefInfo(
    "clothingSizes",
    "clothingSizeId",
    "clothingSize",
    "clothingSizeIds",
    "clothingSizes"
  ),
  new EntityRefInfo(
    "professionalRoles",
    "professionalRoleId",
    "professionalRole",
    "professionalRoleIds",
    "professionalRoles"
  ),
  new EntityRefInfo("leagues", "leagueId", "league"),
  new EntityRefInfo(
    "competitorGroups",
    "groupId",
    "group",
    "competitorGroupIds",
    "competitorGroups"
  ),
  new EntityRefInfo("competitions", "competitionId", "competition"),
];

let entityRefCols = {};
let entityRefIdProps = {};
let entityRefIdColProps = {};

entityRefInfos.forEach((info) => {
  entityRefCols[info.colName] = info;
  if (info.idPropName != null) {
    entityRefIdProps[info.idPropName] = info;
  }
  if (info.idColPropName != null) {
    entityRefIdColProps[info.idColPropName] = info;
  }
});

/** Assuming data contains an EntityRefs object, either unwrapped or as .refs,
 * return an object containing id -> obj maps for each listed type.
 */
function buildRefMaps(data) {
  let maps = {};
  // Support refs in .refs as well as in response root
  let refs = "refs" in data ? data["refs"] : data;

  entityRefInfos.forEach((info) => {
    let colName = info.colName;
    if (!(colName in refs)) return;

    let map = {};
    maps[colName] = map;

    let col = refs[colName];
    col.forEach((o) => {
      map[o[settings.propNames.id]] = o;
      if (colName === "teams" && "teamMembers" in o) {
        // special case: add teamMembers to maps.competitors
        o.teamMembers.forEach((mo) => {
          if (maps.competitors == null) maps.competitors = {};
          maps.competitors[mo[settings.propNames.id]] = mo;
        });
      }
    });
    delete refs[colName];
  });

  return maps;
}

function _expandRefs(obj, maps) {
  if (Array.isArray(obj)) {
    for (let idx = 0; idx < obj.length; idx++) _expandRefs(obj[idx], maps);
  } else if (_.isPlainObject(obj)) {
    _.forIn(obj, (value, name) => {
      let info = null;
      if (name in entityRefIdProps) {
        info = entityRefIdProps[name];
        if (info.colName in maps && !(info.propName in obj)) {
          obj[info.propName] = maps[info.colName][obj[info.idPropName]];
          delete obj[info.idPropName];
        }
      } else if (name in entityRefIdColProps) {
        info = entityRefIdColProps[name];
        if (info.colName in maps && !(info.colPropName in obj)) {
          obj[info.colPropName] = obj[info.idColPropName].map(
            (id) => maps[info.colName][id]
          );
          delete obj[info.idColPropName];
        }
      } else {
        _expandRefs(value, maps);
      }
    });
  }
  return obj;
}

/** Walk through data and expand xyzId -> xyz using the results of buildRefMaps() */
function expandRefs(data) {
  let maps = data.maps;
  let hasRefs = "refs" in data;

  // first, expand cross-refs within maps
  _.forIn(maps, (value, name) => {
    _expandRefs(Object.values(value), maps);
  });
  _.forIn(data, (value, name) => {
    if (
      name !== "maps" &&
      name !== "refs" &&
      (hasRefs || !(name in entityRefCols))
    ) {
      _expandRefs(value, maps);
    }
  });
}

function popParam(params, name) {
  let v = null;
  if (name in params) {
    v = params[name];
    delete params[name];
  }
  return v;
}

function filterParams(params) {
  if (params == null) return null;

  let o = {};
  _.forEach(params, (v, k) => {
    if (v != null) o[k] = v;
  });
  return o;
}

function paramsToQueryString(params) {
  let sp = new URLSearchParams();
  _.forEach(params, (v, k) => {
    if (Array.isArray(v)) {
      _.forEach(v, (i) => {
        sp.append(k, i);
      });
    } else {
      sp.append(k, v);
    }
  });
  return sp.toString();
}

function buildUri(parts) {
  return parts.join("/");
}

/** Call the sys/info EP; if returned promise resolves, system is alive */
function csPing() {
  let uri = apiRoot + "/sys/info";
  let req = new Request(uri, { method: "GET", credentials: "same-origin" });
  return fetch(req).then((resp) => {
    if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText}`);

    return resp.json();
  });
}

// retry once/5 secs
const API_PING_INTERVAL = 5000;
// give up after 15 mins
const API_STATUS_TIMEOUT = 900 * 1000;
// refresh server version at least once every 15 mins
const API_VERSION_CHECK_INTERVAL = 15 * 60 * 1000;

class APIStatus {
  clientScmHash = process.env.VUE_APP_SCM_HASH;

  constructor() {
    this.lastPoll = null;
    this._down = false;
    this.downSince = null;
    this.upSince = Date.now();
    this._sysInfo = null;
    this._callbacks = [];
  }

  get up() {
    return !this._down;
  }

  get down() {
    return this._down;
  }
  set down(v) {
    if (v === this._down) return;

    console.info(`APIStatus.down ${this._down} -> ${v}`);
    this._down = v;

    if (v) {
      this.downSince = Date.now();
      this.upSince = null;
      this._schedulePoll();
    } else {
      this.upSince = Date.now();
      this.downSince = null;
    }

    this._callCallbacks();
  }

  get sysInfo() {
    return this._sysInfo;
  }
  set sysInfo(v) {
    let prevInfo = this._sysInfo;
    let prevHash = prevInfo != null ? prevInfo.scmHash : null;

    this._sysInfo = v;
    if (prevHash === v.scmHash) return;

    let msg = null;
    if (prevHash == null) {
      msg = `sysInfo.scmHash=${v.scmHash}`;
    } else {
      msg = `sysInfo.scmHash ${prevHash} -> ${v.scmHash}`;
    }

    console.debug(msg);
  }

  get serverScmHash() {
    return this.sysInfo != null ? this.sysInfo.scmHash : null;
  }

  get updateAvailable() {
    if (this.sysInfo == null || this.sysInfo.siteRole === "dev") return false;

    return (
      this.sysInfo.scmHash != null &&
      this.clientScmHash !== this.sysInfo.scmHash
    );
  }

  /** Return a promise that resolves when the system is back up */
  waitUp() {
    return this.up ? Promise.resolve() : util.until(() => this.up, 500);
  }

  refreshInfo() {
    if (!this.down && this._needsRefresh()) {
      this._poll();
    }
  }

  addCallback(f) {
    this._callbacks.push(f);
  }

  removeCallback(f) {
    _.remove(this._callbacks, (v) => v === f);
  }

  _needsRefresh() {
    return (
      this.serverScmHash == null ||
      this.lastPoll + API_VERSION_CHECK_INTERVAL < Date.now()
    );
  }

  _schedulePoll() {
    if (this.downSince + API_STATUS_TIMEOUT > Date.now()) {
      setTimeout(() => this._poll(), API_PING_INTERVAL);
    } else {
      console.warn(
        "System has been down longer than " +
          API_STATUS_TIMEOUT / 1000 +
          " secs. Giving up."
      );
    }
  }

  _poll() {
    csPing()
      .then((sysInfo) => {
        this.lastPoll = Date.now();
        this.down = false;
        this.sysInfo = sysInfo;
      })
      .catch(() => {
        this.lastPoll = Date.now();
        this.down = true;
        this._schedulePoll();
      });
  }

  _callCallbacks() {
    // console.info("calling " + this._callbacks.length + " callback(s)");
    this._callbacks.forEach((cb) => cb(this));
  }
}

const status = new APIStatus();

function initResponse(data) {
  if (data) {
    settings.mangle(data);
    data.maps = buildRefMaps(data);
    expandRefs(data);
  }
  return data;
}

class APIError extends Error {
  constructor(msg, status, response) {
    super(msg);
    this.status = status;
    this.response = response;
  }
}

function csRequest(relativeUri, method, data, opts) {
  opts = Object.assign({ bodyType: "json" }, opts);
  status.refreshInfo();

  let uri = apiRoot + "/" + relativeUri;
  let body = null;
  let headers = {};

  // null values need not be included
  data = filterParams(data);

  let hasBody = ["GET", "HEAD"].indexOf(method) === -1;
  if (hasBody && data == null) {
    // we need to POST something
    data = {};
  }

  if (data) {
    if (hasBody) {
      if (opts.bodyType === "json") {
        body = JSON.stringify(settings.unmangle(data));
        headers["Content-Type"] = "application/json; charset=UTF-8";
      } else if (opts.bodyType === "formData") {
        body = new FormData();
        _.forIn(data, (value, name) => {
          body.append(name, value);
        });
      }
    } else {
      // add data as a query string
      if (!_.isEmpty(data)) {
        uri = uri + "?" + paramsToQueryString(data);
      }
    }
  }

  let makeReq = () =>
    new Request(uri, { method, headers, body, credentials: "same-origin" });
  let req = makeReq();
  let errMsg = null;
  let errStatus = null;
  let isError = false;

  return status
    .waitUp()
    .then(() => fetch(req))
    .then((resp) => {
      if (!resp.ok && resp.status === 503) {
        // backend is down; wait until it's backup and retry
        status.down = true;
        return status.waitUp().then(() => fetch(makeReq()));
      } else {
        // next step
        return resp;
      }
    })
    .catch((err) => {
      // assume this means some kind of a network error and treat it as a "system down" situation
      // Note: if this causes weird retry loops because of unrelated errors, re-think!
      let dummy = err; // shut up eslint
      status.down = true;
      return status.waitUp().then(() => fetch(makeReq()));
    })
    .then((resp) => {
      if (!resp.ok) {
        errMsg = `Request failed: ${method} ${uri} -> ${resp.status}`;
        errStatus = resp.status;
        isError = true;
      }

      let contentType = resp.headers.get("Content-Type");
      if (contentType != null) {
        // "x/y; charset=.." -> "x/y"
        contentType = contentType.split(/[ ;]+/)[0];
      }

      switch (contentType) {
        case "application/json":
          return resp.json();
        case "text/plain":
          return resp.text();
        case "application/vnd.ms-excel":
          return resp.blob();
        case null:
          return "";
        default:
          return Promise.reject(
            new Error(`Unhandled Content-Type '${contentType}'`)
          );
      }
    })
    .then((rdata) => {
      if (!isError) {
        if (typeof rdata === "object") {
          rdata = initResponse(rdata);
        }
        return rdata;
      } else {
        return Promise.reject(new APIError(errMsg, errStatus, rdata));
      }
    });
}

function csGet(relativeUri, params) {
  return csRequest(relativeUri, "GET", params);
}

function csPost(relativeUri, params, opts) {
  return csRequest(relativeUri, "POST", params, opts);
}

function csDelete(relativeUri, params) {
  return csRequest(relativeUri, "DELETE", params);
}

function csPut(relativeUri, params, opts) {
  return csRequest(relativeUri, "PUT", params, opts);
}

/**
 * Note: market is optional
 */
function competitionInfo(market) {
  if (market) {
    return csGet(buildUri(["competition-info", market]));
  } else {
    return csGet("competition-info");
  }
}

const auth = {
  admin: {
    login(params) {
      return csPost(buildUri(["auth", "admin", "login"]), params);
    },
    resetPassword(params) {
      return csPost(buildUri(["auth", "admin", "reset-password"]), params);
    },
  },
  competitor: {
    login(params) {
      return csPost(
        buildUri([
          "auth",
          "competitor",
          "login",
          popParam(params, "market"),
          popParam(params, "district"),
        ]),
        params
      );
    },
    resetPassword(params) {
      return csPost(
        buildUri([
          "auth",
          "competitor",
          "reset-password",
          popParam(params, "market"),
          popParam(params, "district"),
        ]),
        params
      );
    },
  },
  login(params) {
    return csPost(buildUri(["auth", "login"]), params);
  },
  logout(params) {
    return csPost(buildUri(["auth", "logout"]), params);
  },
  whoami() {
    return csGet(buildUri(["auth", "whoami"]));
  },
  passwordPolicy(params) {
    let parts = ["auth", "password-policy"];
    if (params != null && params.userType != null)
      parts.push(popParam(params, "userType"));
    return csGet(buildUri(parts));
  },
  changePassword(params) {
    return csPost(buildUri(["auth", "change-password"]), params);
  },
};

const competitions = {
  list: () => csGet(buildUri(["competitions"])),
  get: (params) =>
    csGet(buildUri(["competitions", popParam(params, "market")]), params),
  updateTimeZone: (params) =>
    csPost(
      buildUri(["competitions", popParam(params, "competition"), "timezone"]),
      params
    ),
  export: (params) =>
    csGet(
      buildUri(["competitions", popParam(params, "competition"), "export"])
    ),
  registration: (params) =>
    csPost(
      buildUri([
        "competitions",
        popParam(params, "competition"),
        "registration",
      ]),
      params
    ),
};

const competitor = {
  details: () => csGet(buildUri(["competitor", "details"])),
  updateDetails: (params) =>
    csPost(buildUri(["competitor", "details"]), params),
  teamDetails: () => csGet(buildUri(["competitor", "team-details"])),
  updateTeamDetails: (params) =>
    csPost(buildUri(["competitor", "team-details"]), params),
  registrationInfo: (params) =>
    csPost(buildUri(["competitor", "registration-info"]), params),
  registrationStatus: (params) =>
    csPost(buildUri(["competitor", "registration-status"]), params),
  register: (params) => csPost(buildUri(["competitor", "register"]), params),
  listQuestionnaires: () => csGet(buildUri(["competitor", "questionnaires"])),
  getQuestionnaire: (params) =>
    csGet(
      buildUri([
        "competitor",
        "questionnaires",
        popParam(params, "questionnaireId"),
      ])
    ),
  beginQuestionnaire: (params) =>
    csPost(
      buildUri([
        "competitor",
        "questionnaires",
        popParam(params, "questionnaireId"),
        "begin",
      ]),
      params
    ),
  listQuestions: (params) =>
    csGet(
      buildUri([
        "competitor",
        "questionnaires",
        popParam(params, "questionnaireId"),
        "questions",
      ])
    ),
  getQuestion: (params) =>
    csGet(
      buildUri([
        "competitor",
        "questionnaires",
        popParam(params, "questionnaireId"),
        "questions",
        popParam(params, "questionId"),
      ])
    ),
  setQuestionAnswer: (params) =>
    csPost(
      buildUri([
        "competitor",
        "questionnaires",
        popParam(params, "questionnaireId"),
        "questions",
        popParam(params, "questionId"),
      ]),
      params
    ),
  submitAnswers: (params) =>
    csPost(
      buildUri([
        "competitor",
        "questionnaires",
        popParam(params, "questionnaireId"),
        "submit",
      ]),
      params
    ),
};

const library = {
  myResources: () => csGet(buildUri(["library", "my-resources"])),
  myDiploma: () => csGet(buildUri(["library", "my-diploma"])),
};

const news = {
  feed: () => csGet(buildUri(["news", "feed"])),
};

const ranking = {
  getPublicByClass: (params) => {
    return csGet(
      buildUri([
        "ranking",
        "public",
        "class",
        popParam(params, "competitionClass"),
      ]),
      params
    );
  },
  getPublicByClassAndCountry: (params) => {
    return csGet(
      buildUri([
        "ranking",
        "public",
        "class",
        popParam(params, "competitionClass"),
        "country",
        popParam(params, "country"),
      ]),
      params
    );
  },
  getPublicByClassAndDistrict: (params) => {
    return csGet(
      buildUri([
        "ranking",
        "public",
        "class",
        popParam(params, "competitionClass"),
        "district",
        popParam(params, "district"),
      ]),
      params
    );
  },
  getPublicTeamOrCompetitor: (params) => {
    return csGet(
      buildUri([
        "ranking",
        "public",
        popParam(params, "entityType"),
        popParam(params, "entityId"),
      ]),
      params
    );
  },
  getMyLeagues: () => csGet(buildUri(["ranking", "public", "my-leagues"])),
  admin: {
    get: (params) => csGet(buildUri(["ranking", "admin"]), params),
  },
};

// Note: these are administrative EPs
const missions = {
  list: (params) => {
    return csGet("missions", params);
  },
  get: (params) => {
    return csGet(buildUri(["missions", popParam(params, "mission")]), params);
  },
  listCompletions: (params) => {
    return csGet(
      buildUri(["missions", popParam(params, "mission"), "completions"]),
      params
    );
  },
  updateCompletion: (params) => {
    return csPost(
      buildUri([
        "missions",
        popParam(params, "mission"),
        "completions",
        popParam(params, "competitor"),
      ]),
      popParam(params, "completion")
    );
  },
};

const push = {
  topics: () => {
    return csGet(buildUri(["push", "topics"]));
  },
  getSub: () => {
    return csGet(buildUri(["push", "sub"]));
  },
  updateSub: (params) => {
    return csPost(buildUri(["push", "sub"]), params);
  },
};

const text = {
  resources: (language) => csGet(buildUri(["text", "resources", language])),
  status: (language) => csGet(buildUri(["text", "status", language])),
};

const textResources = text.resources;

const session = {
  status: () => {
    return csGet(buildUri(["session", "status"]));
  },
  create: () => {
    return csPost(buildUri(["session", "create"]));
  },
};

const system = {
  activeSessions() {
    return csGet(buildUri(["system", "active-sessions"]));
  },
};

const issues = {
  get(params) {
    return csGet(buildUri(["issues", popParam(params, "issue")]));
  },
  myIssues() {
    return csGet(buildUri(["issues", "my-issues"]));
  },
  create(params) {
    return csPost(buildUri(["issues", "create"]), params);
  },
  addComment(params) {
    return csPost(
      buildUri(["issues", popParam(params, "issue"), "comment"]),
      params
    );
  },
};

const files = {
  getUpload(params) {
    return csGet(buildUri(["files", "uploads", popParam(params, "upload")]));
  },
  deleteUpload(params) {
    return csDelete(buildUri(["files", "uploads", popParam(params, "upload")]));
  },
  createUpload(params) {
    return csPut(buildUri(["files", "uploads"]), params, {
      bodyType: "formData",
    });
  },
  updateUpload(params) {
    return csPut(
      buildUri(["files", "uploads", popParam(params, "upload")]),
      params,
      { bodyType: "formData" }
    );
  },
};

const qstats = {
  structure(params) {
    return csGet(buildUri(["qstats", "structure"]), params);
  },
  answers(params) {
    return csGet(buildUri(["qstats", "answers"]), params);
  },
  competitions(params) {
    return csGet(buildUri(["qstats", "competitions"]), params);
  },
  categories(params) {
    return csGet(buildUri(["qstats", "categories"]), params);
  },
  rounds(params) {
    return csGet(buildUri(["qstats", "rounds"]), params);
  },
};

const menu = {
  topMenu: () => {
    return csGet(buildUri(["menu", "top-menu"]));
  },
};

const competitionTemplates = {
  get: (params) => {
    return csGet(
      buildUri([
        "competition-templates",
        popParam(params, "competitionTemplate"),
      ]),
      params
    );
  },
  list: (params) => {
    return csGet("competition-templates", params);
  },
  listCompetitions: (params) => {
    return csGet(
      buildUri([
        "competition-templates",
        popParam(params, "competitionTemplate"),
        "competitions",
      ]),
      params
    );
  },
  toggleCompetition: (params) => {
    return csPost(
      buildUri([
        "competition-templates",
        popParam(params, "competitionTemplate"),
        "toggle",
      ]),
      params
    );
  },
  update: (params) => {
    return csPost("competition-templates", params);
  },
};

const roundTemplates = {
  list: (params) => {
    return csGet("round-templates", params);
  },
};

const adminUsers = {
  get: (params) => {
    return csGet(
      buildUri(["admin-users", popParam(params, "adminUser")]),
      params
    );
  },
  list: (params) => {
    return csGet("admin-users", params);
  },
};

const markets = {
  list: (params) => {
    return csGet("markets", params);
  },
};

const countries = {
  list: (params) => {
    return csGet("countries", params);
  },
};

const languages = {
  list: (params) => {
    return csGet("languages", params);
  },
  update: (params) => {
    return csPost("languages", params);
  },
  delete: (params) => {
    return csDelete(
      buildUri(["languages", popParam(params, "languageId")]),
      params
    );
  },
};

const clothingSizes = {
  list: (params) => {
    return csGet("clothing-sizes", params);
  },
};

const questionCategories = {
  get: (params) => {
    return csGet(
      buildUri(["question-categories", popParam(params, "questionCategory")]),
      params
    );
  },
  list: (params) => {
    return csGet("question-categories", params);
  },
  update: (params) => {
    return csPost("question-categories", params);
  },
  delete: (params) => {
    return csDelete(
      buildUri(["question-categories", popParam(params, "questionCategory")]),
      params
    );
  },
};

const competitionClasses = {
  list: (params) => {
    return csGet("competition-classes", params);
  },
};

const professionalRoles = {
  list: (params) => {
    return csGet("professional-roles", params);
  },
  update: (params) => {
    return csPost("professional-roles", params);
  },
  delete: (params) => {
    return csDelete(
      buildUri(["professional-roles", popParam(params, "professionalRole")]),
      params
    );
  },
};

const districts = {
  get: (params) => {
    return csGet(buildUri(["districts", popParam(params, "district")]), params);
  },
  list: (params) => {
    return csGet("districts", params);
  },
  update: (params) => {
    return csPost("districts", params);
  },
  delete: (params) => {
    return csDelete(
      buildUri(["districts", popParam(params, "district")]),
      params
    );
  },
};

const competitors = {
  get: (params) => {
    return csGet(
      buildUri(["competitors", popParam(params, "competitor")]),
      params
    );
  },
  list: (params) => {
    return csGet("competitors", params);
  },
  update: (params) => {
    return csPost("competitors", params);
  },
  delete: (params) => {
    return csDelete(
      buildUri(["competitors", popParam(params, "competitor")]),
      params
    );
  },
};

const teams = {
  get: (params) => {
    return csGet(buildUri(["teams", popParam(params, "team")]), params);
  },
  list: (params) => {
    return csGet("teams", params);
  },
  update: (params) => {
    return csPost("teams", params);
  },
  delete: (params) => {
    return csDelete(buildUri(["teams", popParam(params, "team")]), params);
  },
};

const extprov = {
  updateRoundData: (params) => {
    return csPost(buildUri(["extprov", "round-data"]), params);
  },
};

const performancePoints = {
  update: (params) => {
    return csPost(buildUri(["performance-points", "update"]), params)
  }
}

const timeZones = {
  list: (params) => {
    return csGet(buildUri(["time-zones"]), params);
  },
};

const locales = {
  list: (params) => {
    return csGet(buildUri(["locales"]), params);
  },
};

const enumValues = {
  get: (params) => {
    return csGet(buildUri(["enum-values"]), params)
  }
}

export default {
  configure,
  settings,
  auth,
  competitionInfo,
  competitions,
  competitor,
  textResources,
  library,
  news,
  missions,
  push,
  text,
  ranking,
  session,
  system,
  csPing,
  status,
  issues,
  files,
  qstats,
  menu,
  competitionTemplates,
  roundTemplates,
  adminUsers,
  markets,
  countries,
  languages,
  clothingSizes,
  questionCategories,
  competitionClasses,
  professionalRoles,
  districts,
  competitors,
  teams,
  extprov,
  performancePoints,
  timeZones,
  locales,
  enumValues
};
