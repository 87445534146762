
export const state = {
    open: false,
    messages: []
}

export const getters = {
    isOpen() {
        return state.open;
    },
    getMessages() {
        return state.messages;
    }
};

export const mutations = {
    setOpen(newState) {
        state.open = newState;
    },
    setMessages(newState) {
        state.messages = newState;
    }
}

export const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}
