
export const state = {
    entryRoute: null
}

export const getters = {
    getEntryRoute() {
        return state.entryRoute;
    }
};

export const mutations = {
    setEntryRoute(newState) {
        state.entryRoute = newState;
    }
}

export const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}
