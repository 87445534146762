
import { marked } from "marked"
import "./style/md.scss"

const markedOpts = {breaks: true, headerIds: false};

class Renderer extends marked.Renderer {
  constructor(src, opts) {
    super(opts);
    this.src = src;
  }

  link(href, title, text) {
    let ri = this._remapResource(href, title);
    return super.link(ri.href, ri.title, text);
  }

  image(href, title, text) {
    let ri = this._remapResource(href, title);
    return super.image(ri.href, ri.title, text);
  }

  /**
   * Return {href: ..., title: ...}, possibly aided by this.resources
   */
  _remapResource(href, title) {
    let ri = this._resourceInfo(href);
    if(ri == null) {
      return {href, title};
    }
    if(!ri.title) {
      ri.title = title;
    }
    return ri;
  }

  _resourceInfo(href) {
    let r = null;
    if(this.src.resources != null) {
      r = this.src.resources[href];
    }

    if(r != null) {
      if(typeof r === "string") {
        // support href->href mapping as well as href->{href: .., title:} mapping
        r = {href: r};
      } else {
        // clone the object so we can modify our copy
        r = Object.assign({}, r);
      }
    }

    return r;
  }
}

/** 
 * Render {type: "md|inline-md", value: "..."} into html
 * 
 * If src.opts is set, it's merged with the default marked opts.
 *
 * If src.resources is set, it remaps link and image href:s. 
 * For example, with:
 *  resources: {"a.png": "/images/a.png", somelink: "/somewhere/some/link"}
 * 
 * 1. An image referencing "a.png" will get src="/images/a.png"
 * 2. A link to "somelink" will get href="/somewhere/some/link"
 */
function renderMarkdown(src) {
  let opts = Object.assign({}, markedOpts, src.opts);
  let renderer = new Renderer(src, opts);

  opts.renderer = renderer;
  
  let html = src.type === "inline-md" ? marked.parseInline(src.value, opts) : marked.parse(src.value, opts);

  return html;
}

export default {
  marked,
  markedOpts,
  renderMarkdown
};
