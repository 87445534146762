import _ from "lodash"

/** Rename properties in an object tree.
 *
 * For each plain object starting at obj, rename properties as per propMap.
 */
function renameProps(obj, propMap) {
  if(Array.isArray(obj)) {
    for(let idx = 0; idx < obj.length; idx++)
      renameProps(obj[idx], propMap);
  } else if(_.isPlainObject(obj)) {
    for(let name in obj) {
      let value = obj[name];
      if(name in propMap) {
        let newName = propMap[name];
        obj[newName] = value;
        delete obj[name];
      } else {
        renameProps(value, propMap);
      }
    }
  }
  return obj;
}

/** Walk an object tree and rename "@type" -> "_type" and "id" -> "_id"
 * 
 * This is needed for Vue
 */
function vueifyProps(obj) {
  return renameProps(obj, { "@type": "_type", "id": "_id" });
}

/** Walk an object tree and reverse what vueifyProps does
 * 
 * Useful just before POST:ing a json structure to the API
 */
function unvueifyProps(obj) {
    // Since we are dealing with a reference, better ensure we do not perform any illegal mutations by creating a copy
  let objCopy = _.cloneDeep(obj);
  return renameProps(objCopy, { "_type": "@type", "_id": "id" });
}

/** Decode a Vista identity token (compact JWT) into an object */
function decodeIdentity(token) {
  let parts = token.split("\.");
  let payload = JSON.parse(atob(parts[1]));

  return payload;
}

/** Pick up a value provided by the web view in the global __csData object.
 */
function getCsData(name) {
  let r = null;
  if(window.__csData != null) {
    r = window.__csData[name];
  }
  return vueifyProps(r);
}

/** Return a promise that waits for cond() to be true before resolving.
 *
 * cond is checked once per interval millis (default 1000).
 */
function until(cond, interval) {
  if(interval == null)
    interval = 1000;

  const pollCond = resolve => {
    if(cond()) {
      resolve();
    } else {
      setTimeout(() => pollCond(resolve), interval);
    }
  }

  return new Promise(pollCond);
}

/** Return a reversed copy of an array */
function reversed(a) {
  let r = null;
  if(a != null) {
    r = Array.from(a);
    r.reverse();
  }
  return r;
}

export default {
  renameProps,
  vueifyProps,
  unvueifyProps,
  decodeIdentity,
  getCsData,
  until,
  reversed
}
