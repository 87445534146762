
export const state = {
    cache: new Map()
}

export const getters = {
    get(src) {
        return state.cache.get(src);
    }
};

export const mutations = {
    set(src, svg) {
        state.cache.set(src, svg);
    },
    delete(src) {
        state.cache.delete(src)
    }
}

export const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}
