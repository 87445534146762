import { createApp } from "vue"

/** Vue object preparation common to all entry points
 */
function createVueApp(opts) {
  let app = createApp(opts);
  
  app.config.globalProperties.$scmBranch = process.env.VUE_APP_SCM_BRANCH;
  app.config.globalProperties.$skinId = process.env.VUE_APP_SKIN_ID;
  app.config.globalProperties.$scmHash = process.env.VUE_APP_SCM_HASH;

  return app;
}

export default {
  createVueApp
}
